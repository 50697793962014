import gql from 'graphql-tag';

export const GET_TOURNAMENT_GROUPS = gql`
  query GetTournamentPools($limit: Int!, $offset: Int!, $sorts: [PropertySortInput!], $orgId: UUID!) {
    paginatedTournamentPools(
      paginationOptions: { limit: $limit, offset: $offset, sorts: $sorts }
      tournamentPoolFilter: { organisationId: $orgId }
    ) {
      totalItems
      items {
        id
        name
        category
        tournamentCount
        toDate
        fromDate
        createdByFirstName
        createdByLastName
        level {
          id
          name
        }
      }
    }
  }
`;
export const clearTPs = (cache) => {
  Object.keys(cache.data.data).forEach((key) => key.match(/paginatedTournamentPools/) && cache.data.delete(key));
};

export const DELETE_TOURNAMENTS_POOL = gql`
  mutation DeleteTournamentPool($tournamentPoolId: UUID!) {
    deleteTournamentPool(tournamentPoolId: $tournamentPoolId) {
      id
    }
  }
`;
