// extracted by mini-css-extract-plugin
export var addGroupFormBody = "create-tournament-group-module--add-group-form-body--044bc";
export var bodyBase = "create-tournament-group-module--body-base--b05bf create-tournament-group-module--site-font--fa1b5";
export var bodyLarge = "create-tournament-group-module--body-large--e164b create-tournament-group-module--body-base--b05bf create-tournament-group-module--site-font--fa1b5";
export var bodyLargeBold = "create-tournament-group-module--body-large-bold--4d38d create-tournament-group-module--body-base--b05bf create-tournament-group-module--site-font--fa1b5";
export var bodyRegular = "create-tournament-group-module--body-regular--40c60 create-tournament-group-module--body-base--b05bf create-tournament-group-module--site-font--fa1b5";
export var bodyRegularBold = "create-tournament-group-module--body-regular-bold--9d41c create-tournament-group-module--body-base--b05bf create-tournament-group-module--site-font--fa1b5";
export var bodySmall = "create-tournament-group-module--body-small--f307e create-tournament-group-module--body-base--b05bf create-tournament-group-module--site-font--fa1b5";
export var bodySmallBold = "create-tournament-group-module--body-small-bold--8fc58 create-tournament-group-module--body-base--b05bf create-tournament-group-module--site-font--fa1b5";
export var borderTop = "create-tournament-group-module--border-top--66a5c";
export var breakWordContainer = "create-tournament-group-module--break-word-container--f2e5d";
export var buttonIconBase = "create-tournament-group-module--button-icon-base--695df";
export var clickLink = "create-tournament-group-module--click-link--6f239";
export var dropdownBase = "create-tournament-group-module--dropdown-base--6d4e4";
export var dropdownSelectBase = "create-tournament-group-module--dropdown-select-base--9c5b4 create-tournament-group-module--text-input--92ee8";
export var error = "create-tournament-group-module--error--260e2";
export var flexCol = "create-tournament-group-module--flex-col--c235c";
export var formErrorMessage = "create-tournament-group-module--form-error-message--8d8ed";
export var h3 = "create-tournament-group-module--h3--ed85f";
export var h4 = "create-tournament-group-module--h4--cc41c";
export var hoverLink = "create-tournament-group-module--hover-link--7d589";
export var hoverRow = "create-tournament-group-module--hover-row--9c366";
export var membershipContainer = "create-tournament-group-module--membership-container--3dbbd create-tournament-group-module--flex-col--c235c create-tournament-group-module--primary-border--f80bf";
export var membershipHeader = "create-tournament-group-module--membership-header--62b6e";
export var membershipHeading = "create-tournament-group-module--membership-heading--0b542";
export var membershipLabel = "create-tournament-group-module--membership-label--1461d";
export var moreFiltersBorderClass = "create-tournament-group-module--more-filters-border-class--18eee";
export var pageBg = "create-tournament-group-module--page-bg--9abd6";
export var pointer = "create-tournament-group-module--pointer--afe55";
export var primaryBorder = "create-tournament-group-module--primary-border--f80bf";
export var shadowBoxLight = "create-tournament-group-module--shadow-box-light--53a0f";
export var siteFont = "create-tournament-group-module--site-font--fa1b5";
export var slideDownAndFade = "create-tournament-group-module--slideDownAndFade--38ca1";
export var slideLeftAndFade = "create-tournament-group-module--slideLeftAndFade--a5386";
export var slideRightAndFade = "create-tournament-group-module--slideRightAndFade--a6c23";
export var slideUpAndFade = "create-tournament-group-module--slideUpAndFade--4f001";
export var statusDecoration = "create-tournament-group-module--status-decoration--90eeb";
export var textInput = "create-tournament-group-module--text-input--92ee8";
export var textInverted = "create-tournament-group-module--text-inverted--52c80";
export var textMediumDark = "create-tournament-group-module--text-medium-dark--a8627";
export var tooltipFont = "create-tournament-group-module--tooltipFont--a54cb";
export var unstyledButton = "create-tournament-group-module--unstyled-button--25195";