import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import CreateTournamentGroup from 'src/components/create-tournament-group/create-tournament-group';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const CreateTournamentGroupPage = () => {
  return (
    <Router basepath="/tournaments/groups/create-group">
      <CreateTournamentGroupRoute default />
    </Router>
  );
};

type CreateTournamentGroupRouteProps = RouteComponentProps;

const CreateTournamentGroupRoute: React.FC<CreateTournamentGroupRouteProps> = () => {
  return (
    <Layout>
      <SEO title="Create Tournament Group" />
      <PrivateRoute>
        <CreateTournamentGroup />
      </PrivateRoute>
    </Layout>
  );
};

export default CreateTournamentGroupPage;
